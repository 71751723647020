import React, { useState } from 'react';
import { Container, Button } from 'react-bootstrap';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import CollectionList from "./CollectionEditor";
import CollectionList1 from "./CollectionEditor1";
import CollectionList2 from "./CollectionEditor2";
import Order from "./Order";
import ExportOrdersButton from "./ExportOrdersButton";
import DeliverySelection from "./DeliverySelection";
import "../Checkout/checkout.css";

const Inicio = () => {
  const [activeTab, setActiveTab] = useState(null); // Estado único para la pestaña activa

  const handleTabChange = (tab) => {
    setActiveTab(tab); // Cambia la pestaña activa
  };

  return (
    <>
      <Container>
        <div className="back">
          <h2>Bienvenido</h2>
          <Tabs
            defaultActiveKey="productos"
            id="justify-tab-example"
            className="mb-3"
            justify
          >
            <Tab eventKey="productos" title="Productos">
              <Button
                className="btn-inicio"
                variant="secondary"
                onClick={() => handleTabChange('bebidas')}
              >
                Bebidas
              </Button>
              <Button
                className="btn-inicio"
                variant="secondary"
                onClick={() => handleTabChange('salsas')}
              >
                Salsas
              </Button>
              <Button
                className="btn-inicio"
                variant="secondary"
                onClick={() => handleTabChange('combos')}
              >
                Combos
              </Button>
            </Tab>

            <Tab eventKey="ordenes" title="Ordenes">
              <Button
                className="btn-inicio"
                variant="secondary"
                onClick={() => handleTabChange('ordenes')}
              >
                Buscar orden de app
              </Button>
              <Button
                className="btn-inicio"
                variant="secondary"
                onClick={() => handleTabChange('delivery')}
              >
                Delivery
              </Button>
            </Tab>

            <Tab eventKey="cierre" title="Cierre">
              <Button
                className="btn-inicio"
                variant="secondary"
                onClick={() => handleTabChange('cierre')}
              >
                Cierre del día
              </Button>
            </Tab>
          </Tabs>

          {/* Renderiza los componentes según el valor del estado `activeTab` */}
          {activeTab === 'bebidas' && <CollectionList />}
          {activeTab === 'salsas' && <CollectionList1 />}
          {activeTab === 'combos' && <CollectionList2 />}
          {activeTab === 'ordenes' && <Order />}
          {activeTab === 'delivery' && <DeliverySelection />}
          {activeTab === 'cierre' && (
            <div>
              Cierre próximamente...
              <ExportOrdersButton />
            </div>
          )}
        </div>
      </Container>
    </>
  );
};

export default Inicio;
