/* import React from 'react'
import {Link} from 'react-router-dom'
import "../Item/item.css";
const Item = ({item}) => {
  return (
 <Link to={'/item/' + item.id} className='text-decoration-none'>
  <div className='card'  >
 <img className="card-img-top" src={item.image} alt="Productos de la web" />

  <div className='card-body'> 
    <h5 className='card-title'>- Combo: {item.name}</h5>
    <p><b>Clickea para Ampliar info!</b></p>
  </div>
  </div>
 </Link>
  )
}

export default Item */


import React, { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import ItemDetail from '../ItemDetail/ItemDetail';
import "../Item/item.css";

const Item = ({ item }) => {
  const [showModal, setShowModal] = useState(false);

  const handleShow = () => setShowModal(true);
  const handleClose = () => setShowModal(false);

  return (
    <>
      <div className='card' onClick={handleShow} style={{ cursor: 'pointer' }}>
        <img className="card-img-top" src={item.image} alt="Productos de la web" />
        <div className='card-body'>
          <h5 className='card-title'>- Combo: {item.name}</h5>
          <p><b>Clickea para Ampliar info!</b></p>
        </div>
      </div>

      {/* Modal para mostrar ItemDetail */}
      <Modal show={showModal} onHide={handleClose} size="lg" centered>
        <Modal.Header closeButton>
          <Modal.Title>Detalle del Combo</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ maxHeight: '400px', overflowY: 'auto' }} >
          <ItemDetail item={item} />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Item;
